<template>
  <Card v-auto-animate>
    
<FormKitLazyProvider config-file="true">
<div data-testid="collapse" @click="onCollapse" class="w-full justify-between flex items-center" :class="{'group cursor-pointer': canCollapse, 'mb-4': !collapsed }" v-auto-animate>
      <GuestInfoChip
        :guest="guestData"
        :guest-maturity="formMaturity"
        :guestCount="guestCount"
        :hasError="false"
        :isCollapsed="collapsed"
      />
      <div class="group-hover:text-navy-500 text-navy" v-if="canCollapse">
        <PhosphorIconCaretDown v-if="collapsed" size="24" />
        <PhosphorIconCaretUp v-else size="24" />
      </div>
    </div>

    <FormKit
      data-testid="form"
      v-auto-animate
      v-if="!collapsed"
      v-bind="formkitSubmitOptions"
      v-model="formModel"
      type="form"
      :id="formName"
      :name="formName"
      @submit="onSubmit"
      @submit-invalid="onSubmitInvalid"
      @input="onInput"
    >
      <FormKitSchema :schema="combinedSchema"  />
    </FormKit>
</FormKitLazyProvider>

  </Card>
</template>

<script lang="ts" setup>
import { FormKitLazyProvider } from '@formkit/vue'
import { FormKitSchema } from '@formkit/vue'
import { buildFormkitObject, generateFormkitSchema, type SchemaFlags } from './checkInFormUtils'
import { type FormKitSchemaDefinition, getNode } from '@formkit/core'
import type { Guest } from '~/types/checkIn';

import { useStorage, useDebounceFn } from '@vueuse/core'

const emit = defineEmits(['initForm', 'formComplete', 'formEdit', 'formStopEdit', 'canSubmit'])

const layoutWidth = inject<Ref>('layoutWidth')
const props = defineProps<{
  guest: Guest;
  canCollapse?: boolean
  guestCount?: number
}>()

const { guests } = storeToRefs(useWebsiteStore())
const { updateGuest } = useWebsiteStore()
const { getFormStateByGuestId, formByGuestId } = useCheckInStore()

const collapsed = ref(false)
const guestData = toRef(props, 'guest')
const formName = computed(() => `${guestData.value?.guestId}`)
const localFormModel = useStorage(`shackle-${guestData.value?.guestId}`, {})
const formModel = ref()
const formMaturity = ref()

const schemaDisabled = computed(() => {
  const { isComplete, isEditing } = getFormStateByGuestId(guestData.value?.guestId)
  if (isComplete) return true
  if (isEditing) return false
});

const flags = computed<SchemaFlags>(() => {
  return {
    ...getFormStateByGuestId(guestData.value?.guestId),
    isDisabled: schemaDisabled.value ?? false,
    isLargeLayout: layoutWidth?.value > 640,
    formName: formName?.value,
    isPrimary: formModel.value?.guestType === 'PRIMARY',
    isChild: formMaturity.value === 'child',
  }
})

const processedForm = computed<FormKitSchemaDefinition>(() =>  generateFormkitSchema(guestData.value, flags.value))
const formFields = computed(() => formByGuestId(guestData.value?.guestId)?.field.map((field: any) => buildFormkitObject(field, flags.value)))
const combinedSchema = computed(() => {
  const processedFormMap = new Map(Array.isArray(processedForm.value) ? processedForm.value.map((field: any) => [field.props?.id, field]) : [])
  const formSchemaMap = new Map(formFields.value?.map((field: any) => [field.props?.id, field]) || [])
  const combinedFields = new Map([...processedFormMap, ...formSchemaMap]).values()
  return Array.from(combinedFields)
})
const requiredFields = computed(() => combinedSchema.value.filter((field: any) => field.props?.validation?.includes('required')))

const formkitSubmitOptions = computed(() => {
  const { isComplete, isInitialised, hasError } = getFormStateByGuestId(guestData.value?.guestId)

  const startStage = !isInitialised && !isComplete
  const secondStage = isInitialised && !isComplete
  const thirdStage = !isInitialised && isComplete
  const isSingleGuest = guests.value?.length === 1

  let suffixIcon = ''
  let submitLabel = ''
  let inputClass = 'hidden'
  let actions = false

  if (startStage) {
    actions = true
    submitLabel = 'Next'
    suffixIcon = 'arrowRightCircle'
    inputClass = 'hover:bg-navy-900 bg-navy'
  }

  if (secondStage) {
    actions = !isSingleGuest
    submitLabel = 'Confirm details'
    suffixIcon = 'checkCircle'
    inputClass = 'hover:bg-navy-900 bg-navy'
  }

  if (thirdStage) {
    actions = true
    submitLabel = 'Edit'
    suffixIcon = 'pencil'
    inputClass = 'hover:bg-teal-600 bg-teal-500'
  }

  return {
    actions,
    submitLabel,
    disabled: hasError,
    submitAttrs: {
      'data-testid': 'submit',
      suffixIcon,
      inputClass: `${inputClass} text-white rounded-2xl leading-snug tracking-wide py-3 relative mt-3 flex justify-center items-center`,
      wrapperClass: `group`,
      iconClass: 'absolute transition-transform right-3 scale-75 group-hover:scale-100'
    },
  }
})

onMounted(() => {
  collapsed.value = (props.canCollapse && getFormStateByGuestId(guestData.value?.guestId).isComplete) ? true : false
  formMaturity.value = dateToMaturity(guestData.value?.birthDate)
  if (localFormModel.value) formModel.value = localFormModel.value

  if (getFormStateByGuestId(formModel.value.guestId).isInitialised) {
    onInput(formModel.value)
    emit('initForm', formModel.value) // init on load to ensure we capture server errors
  }
})

const debounceInit = useDebounceFn((formData: any) => {
  emit('initForm', formData)
}, 400)

const onInput = (formData: any) => {
  const initialFields = ['title', 'firstName', 'lastName', 'email', 'birthDate', 'countryOfResidence']
  const wasUpdated = (key: string) => formData[key] !== formModel.value?.[key]
  const exists = (key: string) => key in formData

  const filteredFormData = Object.fromEntries(Object.entries(formData).filter(([key]) => initialFields.includes(key)))

  const allRequiredFieldsReady = requiredFields.value.every((field: any) => {
    const fieldValue = formData[field.props.id]
    return fieldValue?.trim() !== '' && fieldValue !== undefined
  })

  if (exists('birthDate')) formMaturity.value = dateToMaturity(formData.birthDate)
  if (wasUpdated('nationality') && exists('document_issuing_country')) {
    getNode('document_issuing_country')?.input(formData.nationality)
  }

  // always re-init if initial fields were updated
  if (initialFields.some((field: string) => wasUpdated(field))) {
    debounceInit(formData)
  }

  updateGuest(filteredFormData as Guest)

  if (getFormStateByGuestId(formData.guestId).isInitialised) {
    emit('canSubmit', allRequiredFieldsReady)
  }
}

const onCollapse = () => {
  return props.canCollapse ? collapsed.value = !collapsed.value : null
}

const onSubmit = (formData: any) => {
  const { isComplete, isEditing, isInitialised, hasError } = getFormStateByGuestId(guestData.value?.guestId)

  if (hasError) return
  if (isEditing && !isComplete) emit('formStopEdit')

  if (isComplete && !isEditing) {
    emit('formEdit')
    return
  }

  if (!isInitialised && !isComplete) {
    emit('initForm', formData)
    return
  }

  if (isInitialised && !isComplete) {
    formData.status = 'COMPLETED'
    emit('formComplete', formData)
    if (props.canCollapse) collapsed.value = true
    return
  }
}
const onSubmitInvalid = () => {
  // method must be defined - this scrolls to the first error using the formkit plugin
}
</script>

<style scoped>
:deep(.formkit-form) {
  --fk-gap: 1rem;
  gap: var(--fk-gap);
  @media screen and (min-width: 640px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

:deep(.formkit-outer + .formkit-outer) {
  @media screen and (max-width: 640px) {
    margin-top: calc(var(--fk-gap));
  }
}

:deep(.formkit-actions) {
  grid-column: span 2;
}

:deep(.formkit-messages) {
  grid-column: span 2;
  order: 1000;
  margin-top: calc(var(--fk-gap) / 2);
}

:deep([data-invalid] .formkit-label) {
  color: #eb3a3a;
}

:deep([data-invalid] .formkit-input) {
  border-color: #eb3a3a;
}

:deep([data-disabled] .formkit-input) {
  cursor: not-allowed;
  background-color: inherit;
  opacity: .5;
}

:deep([data-complete] .formkit-input) {
  border-color: #2CABB1;
}

:deep([data-invalid] .formkit-input) {
  padding-right: calc(var(--fk-gap) * 2);
}

:deep([data-complete] .formkit-input) {
  padding-right: calc(var(--fk-gap) * 2);
}

:deep(.formkit-inner::before),
:deep(.formkit-inner::after) {
  pointer-events: none;
  position: absolute;
  width: 20px;
  height: 20px;
  right: calc(var(--fk-gap) / 2);
  top: calc(50% - 1px); /**1px accounts for border */
  transform: translateY(-50%);
  transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 1;
}

:deep([data-type="select"][data-invalid] .formkit-inner::before),
:deep([data-type="select"][data-complete] .formkit-inner::before) {
  right: calc(var(--fk-gap) * 2);
}

:deep([data-type="select"] .formkit-inner:before) {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M16.25 7.5 10 13.75 3.75 7.5'/%3E%3C/svg%3E");
}

:deep([data-type="select"][data-disabled] .formkit-inner::before) {
  opacity: 0.5;
}

:deep([data-type="select"] .formkit-input) {
  padding-right: calc(var(--fk-gap) * 2);
}

:deep([data-type="select"][data-complete] .formkit-input) {
  padding-right: calc(var(--fk-gap) * 3.5);
}

:deep([data-type="date"][data-complete] .formkit-input) {
  padding-right: calc(var(--fk-gap) * 2);
}

:deep([data-invalid] .formkit-inner::after) {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23eb3a3a' d='M10 1.875A8.125 8.125 0 1 0 18.125 10 8.14 8.14 0 0 0 10 1.875ZM9.375 6.25a.625.625 0 0 1 1.25 0v4.375a.624.624 0 1 1-1.25 0V6.25ZM10 14.375a.938.938 0 1 1 0-1.875.938.938 0 0 1 0 1.875Z'/%3E%3C/svg%3E");
}

:deep([data-complete] .formkit-inner::after) {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%232CABB1' d='M10 1.875A8.125 8.125 0 1 0 18.125 10 8.14 8.14 0 0 0 10 1.875Zm3.867 6.703L9.29 12.953a.633.633 0 0 1-.867 0l-2.29-2.187a.624.624 0 1 1 .86-.907l1.86 1.774 4.156-3.961a.625.625 0 0 1 .86.906Z'/%3E%3C/svg%3E");
}

:deep([data-type="checkbox"] .formkit-wrapper) {
  grid-template-columns: auto 1fr;
  gap: calc(var(--fk-gap) / 2);
}
:deep([data-type="checkbox"] .formkit-inner::after) {
  content: none;
}

/* safari date input consistency fixes. todo: date icon for safari only */
:deep(input::-webkit-date-and-time-value) {
  text-align: left;
}
:deep(input[type="date"]:empty) {
  min-height: 40px;
}
</style>
