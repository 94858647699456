import type { FormField } from "~/grpc/proto/shackle/backend/common_pb";
import { RegistrationFormV2Response } from "~/grpc/proto/shackle/backend/v1/checkin_pb";

export interface SchemaFlags {
  isDisabled: boolean
  isInitialised: boolean
  isLargeLayout: boolean
  isComplete: boolean
  isPrimary: boolean
  formName: string
  isEditing: boolean
  isChild: boolean
}

type FieldConfig = {
  outerClass: string
  innerClass: string
  order: number
}

type FormKitInputType = 'text' | 'select' | 'date' | 'email' | 'tel' | 'time' | 'file' | 'checkbox' | 'hidden';
type FormInputType = 'textField' | 'selectField' | 'countryField' | 'nationalityField' | 'phoneField' | 'dateField' | 'timeField' | 'imageField' | 'checkboxField';
type RegistrationFormValuePath = [FormInputType, ...string[]];

export const getFormkitInputValidation = (field: FormField, isInitialised: boolean, isPrimary: boolean, isChild: boolean) => {
  const fieldIsMandatory = field.mandatory || ['email', 'birthDate', 'firstName', 'lastName', 'countryOfResidence'].includes(field.id)
  const required = fieldIsMandatory ? 'required' : '';

  switch (field.id) {
    case 'title':
      return isChild ? '' : 'required'
    case 'email':
      if (isChild) return '+email'
      if (!isInitialised) {
        return `${required + '|'}+email`
      } else {
        return isChild ? '' : `${required + '|'}+email`;
      }
    case 'birthDate':
      // Birthdate validation rules:
      // 1. Must be a date before today (no future dates allowed)
      // 2. Always required for all guests
      // 3. For primary guest: Must be at least 18 years old
      // 4. For non-primary guests: No minimum age requirement
      // 5. Must be less than 125 years old
      const dateBefore = `date_before:${isPrimary ? yearsAgoYMD(18) : todayYMD()}`;
      const dateAfter = `date_after:${yearsAgoYMD(125)}`;
      const dateFormat = 'date_format:YYYY-MM-DD';
      return `required|${dateFormat}|${dateBefore}|${dateAfter}`;
    case 'document_issue_date':
      return `${required + ':'}date_before:${todayYMD()}`
    case 'document_expiry_date':
      return `${required + ':'}date_after:${todayYMD(-1)}`
    case 'document_number':
      return `${required + '|'}contains_alphanumeric`
    default:
      return `${required}`;
  }
}

const getFormkitInputType = (field: any): FormKitInputType => {
  // handle non-text fields that exist in the prelimnary form
  if (['guestId', 'guestType', 'status', 'isLastNameReadOnly'].includes(field.id)) return 'hidden';
  if (['countryOfResidence', 'title'].includes(field.id)) return 'select';
  if (['birthDate'].includes(field.id)) return 'date';
  if (field.id === 'email') return 'email';

  const fieldTypeMap: Record<FormInputType, FormKitInputType> = {
    textField: 'text',
    selectField: 'select',
    countryField: 'select',
    nationalityField: 'select',
    phoneField: 'tel',
    dateField: 'date',
    timeField: 'time',
    imageField: 'file',
    checkboxField: 'checkbox',
  };

  for (const key of Object.keys(fieldTypeMap)) {
    if (key in field) return fieldTypeMap[key as FormInputType];
  }

  return 'text';
}

const getFormkitInputOptions = (key: string) => {
  switch (key) {
    case 'title':
      return ["Ms", "Mrs", "Miss", "Mr", "Dr", "Prof", "Rev", "Not Known"]
    case 'nationality':
    case 'countryOfResidence':
    case 'country_of_residence':
    case 'documentIssuingCountry':
    case 'document_issuing_country':
      return iso31661Alpha2
  }
}

const getFormkitInputLabel = (field: FormField, isRequiredField: boolean) => {
  const requiredFields: { [key: string]: string } = {
    title: 'Title',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    birthDate: 'Date of Birth',
    countryOfResidence: 'Country of Residence'
  };

  const label = requiredFields[field.id] ?? field.displayName;
  return isRequiredField ? `${label}*` : label;
}

const getFormkitClasses = (fieldName: string, isLargeLayout: boolean): FieldConfig => {
  const columnSpan = isLargeLayout ? 'col-span-2' : 'col-span-1'
  const widthCalc = `w-[calc(50%-calc(var(--fk-gap)/2))]`

  const dynamicFieldClasses = {
    outerClass: columnSpan,
    innerClass: isLargeLayout ? widthCalc : ''
  }

  const staticFieldClasses = {
    outerClass: 'col-span-1',
    innerClass: ''
  }

  const fieldConfig: { [key: string]: FieldConfig } = {
    title: {
      ...dynamicFieldClasses,
      order: 0
    },
    firstName: {
      ...staticFieldClasses,
      order: 1
    },
    lastName: {
      ...staticFieldClasses,
      order: 2
    },
    email: {
      ...dynamicFieldClasses,
      order: 3
    },
    birthDate: {
      ...dynamicFieldClasses,
      order: 4
    },
    countryOfResidence: {
      ...staticFieldClasses,
      order: 5
    },
    nationality: {
      ...staticFieldClasses,
      order: 6
    },
    document_type: {
      ...dynamicFieldClasses,
      order: 7
    },
    document_number: {
      ...staticFieldClasses,
      order: 8
    },
    document_issuing_country: {
      ...staticFieldClasses,
      order: 9
    },
    document_issue_date: {
      ...staticFieldClasses,
      order: 10
    },
    document_expiry_date: {
      ...staticFieldClasses,
      order: 11
    },
    phone_type: {
      ...staticFieldClasses,
      order: 12
    },
    phone: {
      ...staticFieldClasses,
      order: 13
    },
    address_type: {
      ...dynamicFieldClasses,
      order: 14
    },
    address_line_1: {
      ...staticFieldClasses,
      order: 15
    },
    address_line_2: {
      ...staticFieldClasses,
      order: 16
    },
  };

  return fieldConfig[fieldName] || {
    ...staticFieldClasses,
    order: 999
  }
}

const getValidationMessages = (placeholder: string) => ({
  required: `${placeholder} is required.`,
  number: `${placeholder} must be a number.`,
  contains_alphanumeric: `${placeholder} may only contain letters and numbers.`,
});

const convertFormOptionsToFormkitOptions = (options: any) => {
  if (!options) return []
  return options.map((option: any) => {
    return {
      value: option?.value,
      label: option?.displayName
    }
  })
}

export const buildFormkitObject = (field: any, formAttrs: SchemaFlags) => {
  const { isDisabled, isLargeLayout, isChild, isInitialised, isPrimary, formName } = formAttrs;

  const type = getFormkitInputType(field);

  // @todo integrate with validationRegex
  const validation = getFormkitInputValidation(field, isInitialised, isPrimary, isChild);
  const isRequiredField = validation?.includes('required') || false;
  const label = getFormkitInputLabel(field, isRequiredField) ?? '';
  const placeholder = (label.endsWith('*') ? label.slice(0, -1) : label)

  const [rootValuePath] = getRegistrationFormValuePath(field);

  const getInitialValue = () => {
    if (type === 'date' && field.dateField?.value) return protoDateToInputString(field.dateField.value)
    if (field[rootValuePath]?.value) return field[rootValuePath]?.value
    if (field.value) return field.value
    return ''
  }

  const getInitialOptions = () => {
    if (field.selectField?.option) {
      return convertFormOptionsToFormkitOptions(field.selectField.option)
    }
    if (field.id) {
      return getFormkitInputOptions(field.id);
    }
    return []
  }

  const { outerClass, innerClass, order } = getFormkitClasses(field.id, isLargeLayout);

  const fkObject: any = {
    label,
    type,
    innerClass,
    outerClass,
    validation,
    placeholder,
    inputClass: 'w-full',
    validationVisibilty: 'live',
    validationMessages: getValidationMessages(placeholder),
    id: field.id,
    key: `${formName}-${field.id}`,
    name: field.id,
    dataOrder: order,
    disabled: isDisabled,
    autoComplete: true,
    value: getInitialValue(),
  };

  if (type === 'select') {
    fkObject.options = getInitialOptions()
    if (fkObject.options.length === 1) {
      fkObject.value = fkObject.options[0].value
    }
  }

  return {
    $cmp: 'FormKit',
    props: {
      ...fkObject
    },
  };
};

export const generateFormkitSchema = (form: any, formAttrs: SchemaFlags, formInStore?: RegistrationFormV2Response) => {
  const buildObjectWithAttrs = (field: any) => buildFormkitObject(field, formAttrs);

  const formToFields = (form: any) =>
    Object.entries(form)
      .map(([name, value]) => ({ id: name, value }))

  const storeFormFields = formInStore?.field === undefined ? [] : formInStore?.field ?? [];
  const toFields = formToFields(form).length === 0 ? [] : formToFields(form);
  const finalSchema = [ ...storeFormFields, ...toFields].flatMap(buildObjectWithAttrs);

  // Insert fields that should always be present
  ['title', 'birthDate', 'countryOfResidence', 'email', 'firstName', 'lastName'].forEach((displayName: string) => {
    if (!finalSchema.some((field: any) => field.props?.id === displayName)) {
      finalSchema.push(buildObjectWithAttrs({ id: displayName, name: displayName }))
    }
  })

  return finalSchema
    .filter((item, index, self) => self.findIndex(i => i?.props?.id === item?.props?.id) === index)
    .sort((a, b) => a.props?.dataOrder - b.props?.dataOrder);
};

export const getRegistrationFormValuePath = (input: any): RegistrationFormValuePath => {
  if (input?.id === 'document_issue_date') {
    return ['dateField', 'pastDatesAndCurrentDate', 'from'];
  }

  if (input?.id === 'document_expiry_date') {
    return ['dateField', 'futureDatesAndCurrentDate', 'to'];
  }

  if (input) {
    if (input.textField) return ['textField'];
    if (input.selectField) return ['selectField'];
    if (input.countryField) return ['countryField'];
    if (input.nationalityField) return ['nationalityField'];
    if (input.phoneField) return ['phoneField'];
    if (input.dateField) return ['dateField'];
    if (input.timeField) return ['timeField'];
    if (input.imageField) return ['imageField'];
    if (input.checkboxField) return ['checkboxField'];
  }

  return ['textField']; // Default to 'textField' if no match is found
}

export const buildFormPayload = (guestData: any, form: any) => {
  if (!form || !form.field) return {}

  const field = form.field.map((field: any) => {
    const fieldId = field.id
    const value = guestData[fieldId]

    let payload: any = {}
    let [valueRoot, ...path] = getRegistrationFormValuePath(field)

    switch (valueRoot) {
      case 'textField':
      case 'selectField':
      case 'countryField':
      case 'nationalityField':
      case 'phoneField':
      case 'timeField':
      case 'imageField':
        payload = {
          [valueRoot]: {
            value
          }
        }
        break
      case 'checkboxField':
        if (value === undefined) {
          payload = {
            [valueRoot]: {
              value: value || false
            }
          }
        }
        break
      case 'dateField':
        if (value !== undefined && value !== '') {
          // traverse more complex dateField structure paths
          const protoDateValue = dateToProtoDate(value);

          if (path) {
            const payloadStruct = path.reduceRight<{ [key: string]: any }>((acc, pathItem) => ({
              [pathItem]: acc
            }), protoDateValue);

            payload = {
              [valueRoot]: {
                ...payloadStruct,
                value: protoDateValue
              }
            }

          } else {
            payload = {
              [valueRoot]: protoDateValue
            }
          }
        }
        break
    }

    return {
      ...field,
      ...payload
    }
  })

  return {
    field,
    formOrderIndex: form.formOrderIndex,
    guestFormIdentifier: form.guestFormIdentifier
  }
}
